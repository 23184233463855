import React from "react";
import "./Support.scss";

function Support() {
  return (
    <div className="support">
      <h1>Customer Support</h1>
      <p>
        The Koders Customer Support page contains several types of problems that
        you may have faced so that they can be resolved quickly and you can be
        free of stress. For example, you can obtain further project details,
        adjust delivery dates, assistance with refunds, and much more. The
        Koders Customer Support also provides further information on payments,
        project deliveries, and other related topics.
      </p>
      <p>
        The website includes a number of criteria to help you get your questions
        answered quickly, efficiently, and without difficulty. If you require
        expert assistance on a variety of topics, you can call the Koders
        Helpline or access Koders Customer Support. The Support Executive will
        respond quickly to ensure that your experience is positive and
        pleasurable. Please feel free to contact the Koders Customer Support if
        you ever experience any bugs, problems with software updates, or issues
        with system compatibility.
      </p>
      <p>
        You can even notify your loved ones about the help page so that they can
        properly address their issues as well.
      </p>
    </div>
  );
}

export default Support;
